const handleResize = () => {
  console.log('resize')
  const resizeDisplays = document.querySelectorAll(".display");
  const resizeSkills = document.querySelectorAll(".skills")

  window.onresize = (event) => {
    if (window.innerWidth < 1000) {
      for (var i = 0; i < resizeDisplays.length; ++i) {
        resizeDisplays[i].classList.replace('columns--six', 'columns--twelve');
      }
      for (var i = 0; i < resizeSkills.length; ++i) {
        resizeSkills[i].classList.replace('fxcntr--row', 'fxcntr--column');
      }
    } else {
      for (var i = 0; i < resizeDisplays.length; ++i) {
        if (resizeDisplays[i].classList.contains('columns--twelve')) {
          resizeDisplays[i].classList.replace('columns--twelve', 'columns--six');
        }
      }
      for (var i = 0; i < resizeSkills.length; ++i) {
        if (resizeSkills[i].classList.contains('fxcntr--column')) {
          resizeSkills[i].classList.replace('fxcntr--column', 'fxcntr--row');
        }
      }
    }
  };
}

handleResize()